import React, { Fragment, useState } from 'react'
import ClassCard from 'components/user/ClassCard'

const ProfileImage = '/images/dashboard/profile.jpg'

const UserPage: React.FC = () => {
	const [classList] = useState([
		{
			uid: '1',
			name: 'Blockchain Technology',
			teacher: 'Mr. Abcd Wxyz',
			role: 'student',
			url: '/class/1/stream/s',
			profileImg: { ProfileImage },
		},
		{
			uid: '2',
			name: 'Data Structure',
			teacher: 'Mr. Abcd Wxyz',
			role: 'teacher',
			url: '/class/2/stream/t',
			profileImg: { ProfileImage },
		},
	])
	if (typeof classList !== 'undefined' && classList.length > 0) {
		return (
			<div className='container mt-4 mb-4'>
				<div className='row'>
					{classList.map((card) => (
						<ClassCard
							name={card.name}
							teacher={card.teacher}
							key={card.uid}
							uid={card.uid}
							role={card.role}
							url={card.url}
							profileImage={card.profileImg}
						/>
					))}
				</div>
			</div>
		)
	} else {
		return <Fragment></Fragment>
		// return <ClassData image={NoClasses} title="Don't see your Classes?" subtitle='Try contacting your Professor.' offset='2'></ClassData>
	}
}

export default UserPage
