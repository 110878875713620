import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { Col, Row, Card, Container, Figure, Jumbotron } from 'react-bootstrap'
import CardImage from 'images/classes/cardImage.jpg'
import ProfileImage from 'images/dashboard/profile.jpg'
import './classCard.scss'

interface ClassCardProps {
	uid: string
	name: string
	teacher: string
	role: string
	url: string
	profileImage: any
	bgImage?: string
}

const ClassCard: React.FC<ClassCardProps> = ({ uid, name, teacher, role, url, profileImage, bgImage }) => {
	return (
		<Fragment>
			<Col md={4}>
				<Link to={url}>
					<Card className='classCard'>
						<Jumbotron className='classJumbotron px-0' style={{ backgroundImage: `url(${CardImage})` }}>
							<Container className='mt-2'>
								<Row className='align-items-center '>
									<Col xs='10'>
										<h4 className='subName text-light'>
											{/*  */}
											{name}
											{/*  */}
										</h4>
									</Col>

									<Col xs={2}>
										<a href='/classes' className='text-right text-white '>
											<i className='bx bx-dots-vertical-rounded float-right'></i>
										</a>
									</Col>
								</Row>
								<Row className='align-items-center '>
									<Col>
										<p className='text-light'>{teacher}</p>
									</Col>
								</Row>
							</Container>
							<Figure className=' float-right pr-3'>
								<Figure.Image src={ProfileImage} className=' rounded-circle classImg' alt='' />
							</Figure>
						</Jumbotron>
						<Card.Body>
							<Row>
								<Col>
									<h4>TO-DO</h4>
									<div className='toDoList'>
										<ul>
											<li>
												<a href='/assignment' className='card-text text-dark m-0'>
													Assignment 2
												</a>
											</li>
											<li>
												<a href='/quiz' className='card-text text-dark m-0'>
													Quiz 2
												</a>
											</li>
										</ul>
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Link>
			</Col>
		</Fragment>
	)
}

export default ClassCard
